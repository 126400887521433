<template>
  <div style="background-color: white">
    <Head ref="head"></Head>
    <div class="poundBody">
      <!-- 手续费规则说明 -->
      <div class="poundBodyMoudle handlingFeeRule">
        <div class="flexAndCenterAndCenter">
          <img src="../../assets/foot-img/companyIntroduceIcon.png" alt="" style="margin-right: 37px">
          <div>{{ $fanyi('RAKUMART手续费说明') }}</div>
          <img src="../../assets/foot-img/companyIntroduceIcon.png" alt="" style="margin-left: 37px">
        </div>
        <div class="explainHeader">
          <div>{{ $fanyi('根据客户在本网站采购商品的累计总金额设置手续费，') }}</div>
          <div>{{ $fanyi('根据采购总额手续费也不同，详情请参照下表。') }}</div>
        </div>
        <div class="explainFooter">
          <div>{{ $fanyi('*交易手续费的下调是在交易完成超过等级之后才适用。') }}</div>
          <div>{{ $fanyi('例如:实际超过1000万日元以后的交易，适用4.2%的手续费。') }}</div>
        </div>
      </div>
      <div class="footer">
        <div class="footerText">
          <div>{{ $fanyi('公平的佣金，巨大的价值') }}</div>
          <div>{{ $fanyi('用很少的钱利用Rakumart提供的所有服务，更好的帮助您进口') }}</div>
        </div>
        <div class="footerTable">
          <div class="tableHeader">
            <div class="header">会員</div>
            <div class="newFooter">
              <div>代行商品金額合計</div>
              <div>（円）</div>
            </div>
            <div class="section" style="border-bottom: 1px solid #fff">手数料</div>
          </div>
          <div v-for="(item,index) in list" :key="index" class="tableContainer">
            <div class="header">
              <img :src="item.level_icon"/>
              <div>{{ item.level_name }}</div>
            </div>
            <div v-if="item.level_name!=='定額会員'" class="newFooter">
              {{ $fun.yenNumSegmentation(item.consumption) }}
            </div>
            <div v-else class="newFooter">
              <div @click="$fun.routerToPage('/tollVipApplyFor')">{{ $fanyi('詳細へ') }}</div>
            </div>
            <div class="section">
              {{ $fun.returnServiceRate(item.service_rate) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";

export default {
  data() {
    return {
      list: []
    };
  },
  components: {
    Head,
    Foot,
  },
  created() {
    this.$api.getUserLevelRoot().then((res) => {
      this.list = res.data.reverse();
    });
  },
  methods: {
    openChat() {
      this.$refs.head.$refs.sideBar.chat();
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";

.poundBody {
  margin-top: 80px;

  .poundBodyMoudle {
    width: 900px;
    margin: 0 auto;

    .flexAndCenterAndCenter {
      margin-bottom: 49px;

      div {
        margin: 0 36px;
        font-weight: bold;
        font-size: 32px;
        color: #000;
      }
    }

    .explainHeader {
      margin-bottom: 30px;
      line-height: 30px;
      color: #000;
    }

    .explainFooter {
      line-height: 30px;

      div:first-child {
        color: #B4272B;
      }

      div:last-child {
        color: #000;
      }
    }
  }

  .footer {
    padding: 80px 0;
    width: 100%;
    background: #F6F6F6;
    margin-bottom: 30px;

    .footerText {
      text-align: center;
      margin-bottom: 60px;

      div:first-child {
        color: #000000;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 19px;
      }

      div:last-child {
        color: #000000;
        font-size: 16px;
      }
    }

    .footerTable {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .tableHeader {
        .header, .section, .newFooter {
          height: 90px;
          width: 190px;
          display: flex;
          background: #FDF6EC;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #fff;
        }

        .header {
          background: #F7DEB6;
          color: #7D5610;
          font-size: 16px;
        }

        .newFooter {
          display: flex;
          flex-direction: column;
          align-items: center;

          line-height: 30px;
        }
      }

      .tableContainer {
        width: 98px;
        background: #FDF6EC;

        .header {
          width: 98px;
          background: #F7DEB6;
          color: #7D5610;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 90px;
          padding-top: 20px;
          border-right: 1px solid #fff;

          img {
            width: 30px;
            margin-bottom: 8px;
          }

          div {
            color: #7D5610;
            font-size: 14px;
          }
        }

        .section {
          width: 98px;
          height: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: #000000;
          font-size: 14px;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
        }

        .newFooter {
          width: 98px;
          height: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: #000000;
          font-size: 14px;
          border-right: 1px solid #fff;

          div {
            width: 80px;
            height: 42px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 6px;
            text-align: center;
            line-height: 42px;
            cursor: pointer;
            color: #B4272B;
          }

          div:hover {
            background: #FAF2F2;
            border: 1px solid #B4272B;
          }
        }
      }
    }
  }


  .handlingFeeRule {
    margin-bottom: 80px;

    > * {
      text-align: center;
    }

    h1 {
      font-size: 21px;
      font-weight: 500;
      line-height: 50px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 0.9rem;
    }

    .handlingFeeForm {
      width: 960px;
      border-bottom: 1px solid #F9F9F9;

      .FeeFormTitle {
      }

      .FeeFormBody {
      }

      .tul {
        display: flex;
        align-items: center;

        &.FeeFormTitle {
          background: #F0F0F0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          overflow: hidden;
        }

        &.FeeFormBody {
          &:nth-child(2n + 1) {
            background-color: #f9f9fb;
          }
        }

        .tli {

          &.FeeFormTitle {
            background: #f0f3f6;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000000;
            font-size: 14px;
            letter-spacing: 2px;
          }

          &.FeeFormTitle:first-child {
            width: 280px;
          }

          &.FeeFormTitle:nth-child(2) {
            width: 280px;
          }

          &.FeeFormTitle:last-child {
            width: 400px;
          }
        }
      }

      .feeFormBodyContainer {
        height: 100px;
        width: 100%;

        .FeeFormBody1, .FeeFormBody2 {
          width: 280px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .FeeFormBody3 {
          width: 400px;
          display: flex;
          justify-content: center;
          align-items: center;


          div {
            width: 110px;
            height: 42px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 6px;
            text-align: center;
            line-height: 42px;
            cursor: pointer;
            color: #B4272B;
          }

          div:hover {
            background: #FAF2F2;
            border: 1px solid #B4272B;
          }
        }

        img {
          width: 60px;
          height: 60px;
        }
      }

      .feeFormBodyContainer:nth-child(odd) {
        background: #F9F9F9;
      }

      .feeFormBodyContainer:nth-child(even) {
        background: #fff;
      }
    }
  }
}
</style>
