<template>
  <div>
    <Head></Head>
    <div class="page">
      <div class="menu" style="height: 670px;">
        <el-menu
            :default-active="pageIndex"
            class="el-menu-vertical-demo"
            :default-openeds="defaultOpeneds"
        >
          <el-submenu index="3">
            <template slot="title">
              <img src="../../assets/jp-3.png" style="width: 18px;margin-right: 8px" alt=""/>
              <span>{{
                  $fanyi("仕入れ会員")
                }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                  index="3-1"
              >{{ $fanyi("写真撮影") }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="3-2"
              >{{ $fanyi("物流追跡") }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="3-3"
              >{{ $fanyi("FBA直送サービス") }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="3-4"
              >{{ $fanyi("無在庫について") }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <img src="../../assets/jp-2.png" style="width: 18px;margin-right: 8px" alt=""/>
              <span>{{ $fanyi("費用について") }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                  index="4-1"
              >{{ $fanyi("手数料") }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="4-2"
              >{{ $fanyi("国際送料詳細") }}
              </el-menu-item
              >
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="4-3"
              >{{ $fanyi("オプション費用") }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <img src="../../assets/jp-1.png" style="width: 18px;margin-right: 8px" alt=""/>
              <span>{{ $fanyi("会社案内") }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                  index="CommonProblem-1"
              >{{ $fanyi("よくある質問") }}
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item
                  index="CommonProblem-2"
              >{{ $fanyi("問い合わせ") }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div class="mainbox" :style="'height:' + mainHeight + 'px'">
        <div class="Con">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <FootVue style="margin-top: 80px"></FootVue>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import termsService from "./termsService";
import privacyPolicy from "./privacyPolicy";
import comisionesrakumart from "./comisiones-rakumart";
import disclaimer from './disclaimer'
import FootVue from "../../components/foot/Foot.vue";

export default {
  data() {
    return {
      mainHeight: window.innerHeight > 500 ? window.innerHeight - 171 : 800,
      defaultOpeneds: [2, 3, 4, 5], //默认打开
    };
  },
  components: {
    Head,
    termsService,
    privacyPolicy,
    comisionesrakumart,
    disclaimer,
    FootVue,
  },
  computed: {
    pageIndex() {
      return this.$route.name;
    },
  },
  mounted() {
    this.$forceUpdate();
    window.onresize = () => {
      this.mainHeight = window.innerHeight > 500 ? window.innerHeight - 171 : 800;
    };
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";

.page {
  width: 1400px;
  margin: 26px auto 0;
  display: flex;
  align-items: flex-start;

  .menu {
    width: 300px;
    background: #ffffff;
    border-radius: 10px;
    margin-right: 26px;
    overflow-y: auto;
    padding-top: 15px;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
    }

    /deep/ .el-menu-vertical-demo {
      .el-submenu__title {
        font-size: 16px;
        font-weight: bold;
        padding-right: 30px !important;
        line-height: 24px;

        display: flex;
        height: auto;
        padding: 0px 0 !important;
        min-height: 40px;
        align-items: center;
        white-space: unset;
        position: relative;


        span {
          display: block;
          // border: solid;
          flex: 1;
          height: 100%;
          // position: absolute;
          .img2 {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            left: 30px;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }

      .el-submenu__title {
        padding-left: 26px !important;
      }


      .el-menu-item-group {
        height: 36px;

        & .el-menu-item-group__title {
          padding: 0 !important;
        }
      }

      .el-menu-item {
        height: auto;
        min-height: 36px;
        display: flex;
        align-items: center;
        white-space: normal;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        margin: 0 20px;

        &:hover {
          background: #C40622;
          color: #fff;

        }

        &.is-active {
          background: #C40622;
          color: #fff;
        }
      }
    }

    /deep/ .sectitle {
      .el-submenu__title {
        font-weight: 500;

        padding-left: 30px !important;
      }

      position: relative;

      .rowTitle {
        font-size: 14px;
        font-weight: bold;
      }

      .rdtitle {
        padding-left: 60px !important;
        padding-right: 10px !important;
        font-weight: 500;
      }
    }
  }

  .mainbox {
    width: 1140px;
    overflow-y: auto;
    // padding-top: 60px;
    position: relative;
    border-radius: 10px;

    .conNav {
      width: 100%;
      min-height: 30px;
      background-color: white;
      font-size: 16px;
      line-height: 30px;
      padding: 30px 30px 10px 30px;

      position: sticky;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      border-bottom: none;
      top: 1px;
      z-index: 2;

      .fir {
        color: #717171;
      }

      .sec {
        color: #000000;
      }
    }

    .Con {
      width: 100%;
      overflow: hidden;
      border-top: none;
      margin-bottom: 40px;
    }
  }
}
</style>
