<template>
  <div>
    <HomePageTop/>
    <div class="disclaimerPage">
      <div class="fontSize24 fontWeightBold color010101 margin-bottom-30"
           style="border-bottom:1px solid #DFDFDF;padding-bottom:30px ">
        プライバシーポリシー
      </div>
      <div class="margin-top-32 fontWeightBold">RAKUMART 株式会社（以下、「当社」とします。）は、当 HP
        上で提供するサービス（以下,「本サービス」とします。）におけるプライバ
        シー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」とします。）を定めます。
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">1. プライバシー情報</div>
        <div style="line-height:32px">
          プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該
          情報に含まれる氏名、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
          プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購
          入いただいた商品、ご覧になったページや広告の履歴、会員が検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番
          号や性別、職業、年齢、会員の IP アドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
        </div>
      </div>
      <div style="margin-top:75px">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">2. 個人情報を収集・利用する目的</div>
        <div style="line-height:32px" class="margin-bottom-32">
          当社は、ユーザーが利用登録をする際に氏名、住所、電話番号、メールアドレス等の、業務に必要な個人情報を登録して頂いております。ま
          た、ユーザーと提携先などとの間でなされた会員の個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広
          告配信先などを含みます。以下、｢提携先｣とします。）などから収集することがあります。
          当社が個人情報を収集・利用する目的は、以下のとおりです。
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-1 会員に自分の登録情報の閲覧や修正、利用状況の閲覧を行って頂くために、氏名、住所、連絡先、支払方法などの登録情報、利用された
          サービスや購入された商品、およびそれらの代金などに関する情報を表示する目的
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-2 会員にお知らせや連絡をするためにメールアドレスを利用する場合や会員に商品を送付したり必要に応じて連絡したりするため、氏名や
          住所などの連絡先情報を利用する目的
        </div>
        <div class="margin-bottom-32">
          2-3 会員の本人確認を行うために、氏名、住所、電話番号などの情報を利用する目的
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-4 会員に代金を請求するために、購入された商品名や数量、利用されたサービスの種類や期間、請求金額、氏名、住所などの支払に関する
          情報を表示する目的
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-5 会員が簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、会員の指示に基づいて他のサ
          ービスなど（提携先が提供するものも含みます）に転送したりする目的
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-6 代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反した会員や、不正・不当な目的でサービス
          を利用しようとする会員の利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          2-7 会員からのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など当社が会員に対してサービスを提供するに
          あたって必要となる情報や、会員のサービス利用状況、連絡先情報などを利用する目的
        </div>
        <div style="line-height:32px">
          2-8 上記の利用目的に付随する目的
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">3. 行動履歴及び属性情報の取得と利用</div>
        <div> 3-1 当社は、クッキー（Cookie）※や JavaScript 等の技術または第三者のサービス等を利用して、</div>
        <div style="line-height:32px" class="margin-top-8 margin-bottom-32">
          利用者の IP アドレス、利用者がアクセスした URL、コンテンツ、検索キーワード、発信履歴（通話内容は含まない）等の行動に関する情報
          （以下、「行動履歴」とします。）を、当社や当社の提携先（情報提供元、提供者、広告配信先等を含み、以下、「提携先」とします。）の
          サービスを利用する際に取得することがあります。取得した行動履歴や属性情報は、当社サービス利便性の向上及び広告の配信、統計データ
          への活用等を目的に利用することがあります。なおこれらの行動履歴または属性
          情報には個人情報は一切含まれておりません。
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          3-2 当社のクッキー等による行動履歴等及び属性情報を利用したターゲティング広告の配信を希望されない場合は、当社所定の方法に従い、
          「オプトアウト（クッキーの利用に対する拒否）」を行うことで、行動履歴等及び属性情報を利用した当社サービスの利用を停止することが
          できます。
        </div>
        <div class="margin-bottom-32">
          ※携帯電話やスマートフォン、タブレット等から当該ページへアクセスしている場合はオプトアウトできない場合があります。
        </div>
        <div> ※クッキー（Cookie）について、</div>
        <div style="line-height:32px" class="margin-top-8 margin-bottom-32">
          クッキーとは、web ブラウザを通じて web サイトを利用した際に、コンピュータに識別情報を一時的に保存する仕組みです。利用者ごとに
          表示を変えたりするなどの処理に利用されます。ブラウザの設定によりクッキーを拒否することはできますが、クッキーを拒否することで、
          操作性が悪くなったり認証が必要なサービスが利用できない等、インターネット上の各種サービスの利用が制限されたり正しく機能しない場
          合があります。
        </div>
        <div style="line-height:32px" class="margin-bottom-32">
          3-4 当社が、提供を受けた個人情報をもとに、特定の個人を識別できないような形式で統計データを作成することがあります。個人を特定し
          ない当該統計データの利用については、社内外への提供含め、当社は何らの制限なく利用することができるものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">4. 個人情報の第三者提供</div>
        <div class="margin-bottom-32"> 当社の個人情報の第三者提供又は開示に関しては、下記の通りになります。</div>
        <div class="margin-bottom-32">
          <div style="line-height:32px">4-1 原則として、本人の同意を得ずに個人情報を第三者に提供しません。提供先・提供情報内容を特定したうえで、利用者の同意を得た場合
            に限り提供する場合があります。
          </div>
          <div style="margin-bottom: 14px;margin-top: 8px;">
            ただし、以下の場合は、法令に反しない範囲で、本人の同意なく個人情報を提供することがあります。
          </div>
          <div style="margin-bottom: 14px">• 本人が第三者に不利益を及ぼす恐れがあると判断した場合</div>
          <div style="line-height: 32px;">• 人の生命、身体又は財産の保護、公衆衛生の向上または児童の健全な育成の推進のために必要であって、事前の同意を取ることが困難な場合
          </div>
          <div style="line-height: 32px;">• 国の機関若しくは地方公共団体又はその委託を受けた者が法令に基づく行為を遂行することに協力する場合であって、同意を求めることが、
            その行為の遂行に支障を及ぼすおそれがある場合
          </div>
          <div style="margin-top: 10px;margin-bottom: 10px">•
            弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合
          </div>
          <div style="line-height: 32px;">• 法令により開示または提供が許容されている場合。
            利用目的の達成に必要な範囲内において個人情報取扱い業務の一部または全部を委託す
            る場合
          </div>
          <div style="margin-top: 10px">•
            合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合
          </div>
        </div>
        <div style="line-height: 32px;">
          <div>4-2 個人情報の第三者提供に関する免責事項</div>
          <div>• 利用者ご本人以外が識別情報（ID・パスワード等）を公表した場合</div>
          <div>•
            当社サイトからリンクされている第三者企業が運営する外部サイトにおいて、利用者より個人情報が提供され、またそれが利用された場合
          </div>
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">5. 個人情報の開示</div>
        <div class="margin-bottom-32" style="line-height:32px">
          当社は、本人から個人情報の開示を求められたときは、本人に対し、これを開示します。ただし、開示することにより次のいずれかに該当す
          る場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を通知します。
        </div>
        <div class="margin-bottom-32">
          5-1 本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
        </div>
        <div class="margin-bottom-32">
          5-2 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
        </div>
        <div class="margin-bottom-32" style="line-height:32px">
          5-3 その他法令に違反することとなる場合前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則とし
          て開示いたしません。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">6. 個人情報の訂正および削除</div>
        <div style="line-height:32px;margin-bottom: 8px">
          会員は、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除
          を請求することができます。
        </div>
        <div style="margin-bottom: 10px">
          当社は、会員から前項の請求を受けてその請求に応じる必要があると判断した場合には、
        </div>
        <div>
          当該個人情報の訂正または削除を行い、これを会員に通知します。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">7. 個人情報の利用停止等</div>
        <div style="line-height:32px;" class="margin-top-32">
          当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるとい
          う理由により、その利用の停止または 消去（以下、「利用停止等」といいます。）を求められた場合には、必要な調査を行い、その結果に基
          づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等
          を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">8. プライバシーポリシーの変更</div>
        <div style="line-height:32px;" class="margin-top-32">
          本ポリシーの内容は、会員に通知することなく、変更することができるものとします。当社が別途定める場合を除いて、変更後のプライバシ
          ーポリシーは、本サイトに掲載したときから効力を生じるものとします。
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    Foot
  }
}
</script>

<style lang="scss" scoped>
.disclaimerPage {
  width: 1400px;
  margin: 0 auto 30px;
  padding: 32px;
  background-color: #fff;
  border-bottom-left-radius: 10px;

  .margin-top-32 {
    margin-top: 32px
  }

  .margin-top-50 {
    margin-top: 50px
  }

  .margin-top-31 {
    margin-top: 31px
  }

  .margin-top-30 {
    margin-top: 30px
  }

  .margin-top-8 {
    margin-top: 8px
  }

  .margin-bottom-32 {
    margin-bottom: 32px
  }

  .margin-bottom-31 {
    margin-bottom: 31px
  }

  .margin-bottom-30 {
    margin-bottom: 30px
  }
}
</style>
