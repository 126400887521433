<template>
  <div class="option">
    <Head style="margin-bottom: -20px" ref="head"/>
    <div class="main">
      <div class="logoImgBox">
        <div class="imgBoxCon">
          <div>{{ $fanyi('附加服务') }}</div>
          <div style="margin-bottom: 25px">
            {{
              $fanyi('在Rakumart，您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务')
            }}
          </div>
          <div>
            {{ $fanyi('如果您有自己的私人品牌，太好了！请联系我们。') }}
          </div>
        </div>
      </div>
      <div class="packagingContainer">
        <div class="packagingHeaderContainer">
          <div class="packagingHeaderTitle">{{ $fanyi('包装，标签，精细检品') }}</div>
          <div class="colorBorderContainer flexAndCenter">
            <div class="colorBorder1"></div>
            <div class="colorBorder2"></div>
            <div class="colorBorder3"></div>
          </div>
        </div>
        <div class="footerBackground flex">
          <img src="../../assets/footList/servicios-adicionales/cupIcon.png">
          <div class="footerExplainText">
            <div>{{ $fanyi('为您的私人品牌生产定制包装，标签，按照您的要求进行') }}</div>
            <div>{{
                $fanyi('定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价')
              }}
            </div>
            <div>{{ $fanyi('与我们的负责人协商组装等要求') }}</div>
          </div>
        </div>
      </div>
      <div class="ODMContainer">
        <div class="packagingHeaderContainer flex" style="padding-left:139px">
          <div>
            <div class="packagingHeaderTitle">{{ $fanyi('ODM/OEM服务') }}</div>
            <div class="colorBorderContainer flexAndCenter">
              <div class="colorBorder1"></div>
              <div class="colorBorder2"></div>
              <div class="colorBorder3"></div>
            </div>
          </div>
        </div>
        <div class="footerBackground flex">
          <div class="footerExplainText">
            <div>{{
                $fanyi('客户准备一份初稿，我们委托工厂按照设计生产或者对现有产品进行个性化定制，添加客户想要的功能或特性，')
              }}
            </div>
            <div>{{ $fanyi('以便在自有品牌下销售。') }}</div>
            <div>{{ $fanyi('这个过程从工厂购买材料开始，所以要满足一定的产量') }}</div>
            <div>{{ $fanyi('我们将与生产厂家联系，了解生产数量，单价，交货期，并拿到样品与您确认') }}</div>
          </div>
          <img src="../../assets/footList/servicios-adicionales/boxIcon.png">
        </div>
      </div>
      <div class="optionExplainListContainer">
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/1.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('产品摄影') }}</div>
                <div>{{ $fanyi('专业摄影来完成，无论产品什么类型或者多复杂。') }}</div>
              </div>
              <div class="rightBottom borderBottom6586DC">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/2.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('贴标服务') }}</div>
                <div>{{
                    $fanyi('支持各个仓库的贴标服务，比如FBA，乐天，雅虎，定制的标签也可以，请联系负责人进行确认。')
                  }}
                </div>
              </div>
              <div class="rightBottom borderBottomEFA561">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/3.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div style="color: #000;font-size: 20px">{{ $fanyi('更换条码，标签') }}</div>
              </div>
              <div class="rightBottom borderBottom4CA981">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/4.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('更换包装袋') }}</div>
                <div>{{ $fanyi('大件物品我们需要单独报价。') }}
                </div>
              </div>
              <div class="rightBottom borderBottom6586DC">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/5.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('双面打印') }}</div>
                <div>{{ $fanyi('默认为A4纸，其他类型纸张需求请联系负责人确认。') }}</div>
              </div>
              <div class="rightBottom borderBottomEFA561">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/6.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('缝制标签') }}</div>
                <div>{{ $fanyi('缝制个性化标签到您的产品上，如纺织品，衣物等。') }}</div>
              </div>
              <div class="rightBottom borderBottom4CA981">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/7.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('拆卸织物标签') }}</div>
                <div>{{ $fanyi('我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。') }}</div>
              </div>
              <div class="rightBottom borderBottom6586DC">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/8.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('放置吊牌') }}</div>
                <div>{{ $fanyi('在您的产品上按照您的需求放上您的品牌标签。') }}</div>
              </div>
              <div class="rightBottom borderBottomEFA561">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/9.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('移除吊牌') }}</div>
                <div>{{ $fanyi('把商品上带有的吊牌进行移除。') }}</div>
              </div>
              <div class="rightBottom borderBottom4CA981">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/10.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('联合包装') }}</div>
                <div>{{ $fanyi('四件以上的商品联合包装时费用另算，请与负责人联系。') }}</div>
              </div>
              <div class="rightBottom borderBottom6586DC">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rowOptionExplainContainer flexAndCenter">
          <div class="optionExplainContainer flex">
            <div class="left flexAndCenterAndCenter">
              <img src="../../assets/footList/servicios-adicionales/11.png"/>
            </div>
            <div class="right">
              <div class="rightHeader">
                <div>{{ $fanyi('泡沫袋保护') }}</div>
                <div>{{ $fanyi('大件物品需要单独报价，请与负责人联系') }}</div>
              </div>
              <div class="rightBottom borderBottomEFA561">
                1{{ $fanyi('元') }}/{{ $fanyi('個') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";

export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  methods: {
    openChat() {
      this.$refs.head.$refs.sideBar.chat();
    },
  },
};
</script>
<style lang='scss' scoped>
.main {
  min-width: 1400px;
  background-color: white;
  padding-bottom: 1px;

  .moudleP {
    width: 1200px;
    margin: 0 auto;
    padding: 0 100px;
  }

  .logoImgBox {
    background-image: url("../../assets/footList/servicios-adicionales/banner-option.jpg");
    background-position: 50% 50%;
    background-size: 100% auto;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .imgBoxCon {
      width: 1400px;
      padding-top: 148px;
      padding-left: 100px;
      margin-top: -204px;
      color: #FFFFFF;

      div:first-child {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 70px;
      }

      div:last-child, div:nth-child(2) {

        font-size: 20px;
      }
    }
  }

  .packagingContainer {
    width: 1400px;
    margin: 160px auto 190px;

    .packagingHeaderContainer {
      margin-left: 500px;
    }

    .footerBackground {
      width: 100%;
      border-radius: 10px;
      background: #F6F6F6;
      height: 160px;
      padding-left: 134px;

      img {
        width: 287px;
        height: 284px;
        margin-right: 79px;
        margin-top: -145px;
      }

      .footerExplainText {
        color: #000;
        line-height: 36px;
        padding-top: 26px;
      }
    }
  }

  .packagingHeaderTitle {
    color: #000000;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 25px;
  }

  .colorBorderContainer {
    div {
      width: 70px;
      height: 3px;
    }

    .colorBorder1 {
      background: #6586DC;
    }

    .colorBorder2 {
      background: #EFA561;
    }

    .colorBorder3 {
      background: #4CA981;
    }
  }

  .ODMContainer {
    width: 1400px;
    margin: 0 auto 160px;

    .footerBackground {
      width: 100%;
      border-radius: 10px;
      background: #F6F6F6;
      height: 196px;
      padding-left: 139px;

      .footerExplainText {
        color: #000;
        line-height: 36px;
        padding-top: 26px;
      }

      img {
        width: 296px;
        height: 364px;
        margin-left: 56px;
        flex: 1;
        margin-top: -162px;
      }
    }
  }

  .optionExplainListContainer {
    width: 1400px;
    margin: 0 auto;

    .rowOptionExplainContainer {
      padding: 0 75px 30px;
      justify-content: space-between;

      .optionExplainContainer {
        width: 600px;
        height: 180px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .left {
          width: 240px;
          height: 100%;
          min-width: 240px;

          img {
            width: 180px;
            height: 120px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 26px 0 0;

          .rightHeader {
            div:first-child {
              font-weight: bold;
              font-size: 20px;
              margin-bottom: 11px;
              color: #000;
            }

            div:last-child {
              color: #666666;
              font-size: 14px;
            }
          }

          .rightBottom {
            width: 180px;
            height: 54px;
            padding-bottom: 26px;
            color: #666666;
            font-weight: bold;
            font-size: 24px;
          }

          .borderBottom6586DC {
            border-bottom: 3px solid #6586DC;
          }

          .borderBottom4CA981 {
            border-bottom: 3px solid #4CA981;
          }

          .borderBottomEFA561 {
            border-bottom: 3px solid #EFA561;
          }
        }
      }
    }
  }
}
</style>
